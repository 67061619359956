import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { httpRequestInterceptor, httpResponseInterceptor } from './_middlewares/httpInterceptors'
import TagManager from 'react-gtm-module'
import { createRoot } from 'react-dom/client';
import './assets/scss/lib/normalize.css'
import './assets/scss/lib/sanitize.css'
import './assets/scss/lib/pretty-checkbox.css'
import './assets/scss/bootstrap/bootstrap.scss'
import './assets/scss/styles.scss'



// initialisation de google tag manager pour thermor uniquement
let gtmId;
if (window.location.href.indexOf('pac-air-eau') > -1) {
  gtmId = 'GTM-N96FH5N'
} else if (window.location.href.indexOf('pacific') > -1) {
  gtmId = 'GTM-5XR26FT'
} else {
  gtmId = 'GTM-5XR26FT'
}

TagManager.initialize({
  gtmId,
  dataLayerName: 'PageDataLayer'
})

/*Sentry.init({
  dsn: "https://6c1f0464c0c3455ba1c02f61cb26520b@o101133.ingest.sentry.io/5199583",
  environment: ENV,
})*/

httpRequestInterceptor()
httpResponseInterceptor()


const root = createRoot(document.getElementById('root'));
root.render(<App />);

// ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
